import React, {useMemo} from 'react';

const CountryList = ({ countryList }) => {
    return useMemo(
        () =>
            countryList.map(({id, value, name, translateName, patchWebp, patch, link}) => {
                if (value === 'Ru') {
                    return (
                        <div key={id} className={`country__item active notAllowed`}>
                            <div className={`country__circle active`}></div>
                            <p className={`country__name`}>
                                {name} ({translateName})
                            </p>
                            <picture className={`country__image`}>
                                <source type={`image/webp`} srcSet={patchWebp} />
                                <img
                                    src={patch}
                                    alt={`Ru`}
                                    title={`Ru`}
                                    loading={`lazy`}
                                    width={'22'}
                                    height={'22'}
                                />
                            </picture>
                        </div>
                    );
                } else {
                    return (
                        <a key={id} href={link} className={`country__item`}>
                            <div className={`country__circle`}></div>
                            <p className={`country__name`}>
                                {name} ({translateName})
                            </p>
                            <picture className={`country__image`}>
                                <source type={`image/webp`} srcSet={patchWebp} />
                                <img
                                    className={``}
                                    src={patch}
                                    alt={`Kz`}
                                    title={`Kz`}
                                    loading={`lazy`}
                                    width={'22'}
                                    height={'22'}
                                />
                            </picture>
                        </a>
                    );
                }
            }),
        [countryList]
    );
};


export default CountryList;
